import clsx from 'clsx'
import {LANGUAGE_TYPES} from 'constants'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import InputError from '../inputError'
import InputLabel from '../inputLabel'

const Select = ({
  label,
  options = [],
  touched,
  error,
  valueField = 'id',
  labelField = 'name',
  className = '',
  defaultOption = true,
  ...rest
}) => {
  const {t} = useTranslation()
  const {language} = useSelector((state) => state.configs)

  return (
    <>
      {label && <InputLabel label={label} />}
      <select
        className={clsx(
          `form-select form-select-solid form-select-lg fw-bold ${className}`,
          {'is-invalid': touched && error},
          {
            'is-valid': touched && !error,
          },
          {
            'text-align-end': language === LANGUAGE_TYPES.ar,
          }
        )}
        {...rest}
      >
        {defaultOption && <option value=''>{t('select')}</option>}
        {options?.map((item, index) => (
          <option key={index} value={item[valueField]}>
            {item[labelField]}
          </option>
        ))}
      </select>
      {touched && error && <InputError error={error} />}
    </>
  )
}

export default Select
