import React, { useEffect, useState } from "react";
import { Header } from "../../components/Header/Header";
import { CalendarOutlined, CommentOutlined, SmileOutlined, FacebookFilled, InstagramOutlined, TwitterOutlined, PlayCircleFilled } from "@ant-design/icons";
import AccountSection from "../../components/AccountSection";
import Footer from "../../components/Footer";
import AboutBusinessSection from "components/AboutBusinessSection";
import WelcomeSection from "components/WelcomeSection";
import CountUp from "react-countup";
import { LANGUAGE_TYPES } from "constants";
import { useSelector } from "react-redux";
import { Button, Card, Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import { Bg, pricingbg, Expert1, Expert2, Expert3, BriefcaseB, HandshakeB, NoteB, PeopleB, BriefcaseW, HandshakeW, NoteW, PeopleW, StatisticsBG, Puzzle, Calendar, Security } from "assets/index";

import Back from "assets/images/image_72.jpg";
import { getSectionOneDetail } from "services/api/actions";

const About = () => {
  const [aboutBanner, setAboutBanner] = useState([]);
  const { t } = useTranslation();

  const [hoveredCard, setHoveredCard] = useState(null);
  const { language } = useSelector((state) => state.configs);

  const [BannerData, setBannerData] = useState({});

  useEffect(() => {
    getSectionOneDetail(1)
      .then((response) => {
        // Log the entire response to the console

        setAboutBanner(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching banner:", error);
      });
  }, []);

  const videoSection = {
    backgroundImage: `linear-gradient(rgba(45, 53, 140, 0.9), rgba(45, 53, 140, 0.9)), url(${Back})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  const handleMouseEnter = (card) => {
    setHoveredCard(card);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const StatisticSectionStyle = {
    backgroundImage: `linear-gradient(rgba(249, 249, 249, 0.95), rgba(249, 249, 249, 0.95)), url(${StatisticsBG})`,
    backgroundPosition: "right",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",

    display: "flex",
    alignItems: "center",
    // Add more styles as needed
  };
  const separatorStyle = {
    borderLeft: "1px solid #37393F4D",

    height: "220px",
  };

  return (
    <>
      <Header />
      {/* Start banner section */}
      {/* <WelcomeSection
        imageUrl={Bg}
        title="Who we are"
        title_ar="من نحن"
        name="Discover twallam: Our Story, Mission, 
              and Commitment to Excellence"
        name_ar="اكتشف تولام: قصتنا، مهمتنا، 
والالتزام بالتميز"
      /> */}

      <WelcomeSection imageUrl={aboutBanner.image} title={aboutBanner.title} title_ar={aboutBanner.title_ar} name={aboutBanner.long_description} name_ar={aboutBanner.long_description_ar} />

      {/* End banner section */}
      <AboutBusinessSection />

      {/* <section id="our-process" className="process-section">
        <div className="process-container">
          <h4 className="process-top-title">{t("work_process")}</h4>
          <h2 className="process-title">{t("how_we_work")}</h2>

          <Row gutter={[28, 28]}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Card className="process-card appointment" bordered={false}>
                <div className="process-icon">
                  <img src={Calendar} alt="" />
                </div>
                <h3 className="process-heading">{t("make_appointment")}</h3>
                <p className="process-description">{t("work_process_description")}</p>
                <div className="process-button-container">
                  <a href="#testimonials" className="process-button">
                    {t("testimonials")}
                  </a>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Card className="process-card consultation" bordered={false}>
                <div className="process-icon">
                  <img src={Puzzle} alt="" />
                </div>
                <h3 className="process-heading">{t("consultation")}</h3>
                <p className="process-description">{t("work_process_description")}</p>
                <div className="process-button-container">
                  <a href="#testimonials" className="process-button">
                    {t("testimonials")}
                  </a>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Card className="process-card service" bordered={false}>
                <div className="process-icon">
                  <img src={Security} alt="" />
                </div>
                <h3 className="process-heading">{t("enjoy_the_service")}</h3>
                <p className="process-description">{t("work_process_description")}</p>
                <div className="process-button-container">
                  <a href="#testimonials" className="process-button">
                    {t("testimonials")}
                  </a>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </section> */}

      {/* <section id="statistics" style={StatisticSectionStyle} className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "rtl"} statistics-section`}>
        <div className="container">
          <div className="statistics-cards">
            <div className="statistics-card" onMouseEnter={() => handleMouseEnter("briefcase")} onMouseLeave={handleMouseLeave}>
            
              <div
                className="icon"
                style={{
                  background: hoveredCard === "briefcase" ? "#2D358C" : "white",
                }}
              >
                <img src={hoveredCard === "briefcase" ? BriefcaseW : BriefcaseB} alt="" />
              </div>

              <h3>
                <CountUp start={0} end={10} duration={2} suffix="+" />
              </h3>
              <p className="statistics-bottom-title">{t("years_of_experience")}</p>
              <p className="statistics-bottom-text">{t("experience_description")}</p>
            </div>
            <div className="seperator" style={separatorStyle}></div>
            <div className="statistics-card" onMouseEnter={() => handleMouseEnter("handshake")} onMouseLeave={handleMouseLeave}>
         
              <div
                className="icon"
                style={{
                  background: hoveredCard === "handshake" ? "#2D358C" : "white",
                }}
              >
                <img src={hoveredCard === "handshake" ? HandshakeW : HandshakeB} alt="" />
              </div>
              <h3>
                <CountUp start={0} end={596} duration={1} />
              </h3>
              <p className="statistics-bottom-title">{t("satisfied_clients")}</p>
              <p className="statistics-bottom-text">{t("experience_description")}</p>
            </div>
            <div className="seperator" style={separatorStyle}></div>
            <div className="statistics-card" onMouseEnter={() => handleMouseEnter("note")} onMouseLeave={handleMouseLeave}>
             
              <div
                className="icon"
                style={{
                  background: hoveredCard === "note" ? "#2D358C" : "white",
                }}
              >
                <img src={hoveredCard === "note" ? NoteW : NoteB} alt="" />
              </div>
              <h3>
                <CountUp start={0} end={50} duration={3} separator="," suffix="k" />
              </h3>
              <p className="statistics-bottom-title">{t("it_problems_solved")}</p>
              <p className="statistics-bottom-text">{t("experience_description")}</p>
            </div>
            <div className="seperator" style={separatorStyle}></div>
            <div className="statistics-card" onMouseEnter={() => handleMouseEnter("people")} onMouseLeave={handleMouseLeave}>
            
              <div
                className="icon"
                style={{
                  background: hoveredCard === "people" ? "#2D358C" : "white",
                }}
              >
                <img src={hoveredCard === "people" ? PeopleW : PeopleB} alt="" />
              </div>
              <h3>
                <CountUp start={0} end={25} duration={2} suffix="+" />
              </h3>
              <p className="statistics-bottom-title">{t("pro_team_members")}</p>
              <p className="statistics-bottom-text">{t("experience_description")}</p>
            </div>
          </div>
        </div>
      </section> */}

     {/* <section id="our-video" style={videoSection} className={`video-section text-center text-white wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "rtl"} statistics-section`}>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-4">
              <div className="d-flex align-items-center justify-content-center mb-2">
                <div className="video-box"></div>
                <small className="font-weight-medium">{t("short_video")}</small>
              </div>
              <h2 className="font-weight-bold">{t("our_presentation")}</h2>
              <p className="video-para font-weight-normal mb-5">{t("short_video_description")}</p>
              <PlayCircleFilled className="video-icon" />
            </div>
          </div>
        </div>
      </section>

       <section id="our-expert" className="expert-section">
        <div className="expert-container">
          <div className="expert-header">
            <h4 className="expert-title">{t("our_team")}</h4>
            <h2 className="expert-subtitle">{t("meet_our_experts")}</h2>
          </div>
          <Row gutter={[28, 28]}>
            <Col xs={20} sm={8} md={8} lg={8} xl={8}>
              <Card className="expert-card" bodyStyle={{ padding: "0" }} cover={<img alt="example" src={Expert1} />} bordered={false}>
                <div className="expert-icon">
                  <InstagramOutlined className="expert-social" />
                  <FacebookFilled className="expert-social" />
                  <TwitterOutlined className="expert-social" />
                </div>
                <div className="expert-info">
                  <div className="expert-button-container">
                    <a href="#testimonials" className="expert-button">
                      {t("name_1")}
                    </a>
                    <p>{t("managing_director")}</p>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={20} sm={8} md={8} lg={8} xl={8}>
              <Card className="expert-card" bordered={false} bodyStyle={{ padding: "0" }} cover={<img alt="example" src={Expert2} />}>
                <div className="expert-icon">
                  <InstagramOutlined className="expert-social" />
                  <FacebookFilled className="expert-social" />
                  <TwitterOutlined className="expert-social" />
                </div>
                <div className="expert-info">
                  <div className="expert-button-container">
                    <a href="#testimonials" className="expert-button">
                      {t("name_2")}
                    </a>
                    <p>{t("designer")}</p>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={20} sm={8} md={8} lg={8} xl={8}>
              <Card className="expert-card" bordered={false} bodyStyle={{ padding: "0" }} cover={<img alt="example" src={Expert3} />}>
                <div className="expert-icon">
                  <InstagramOutlined className="expert-social" />
                  <FacebookFilled className="expert-social" />
                  <TwitterOutlined className="expert-social" />
                </div>

                <div className="expert-info">
                  <div className="expert-button-container">
                    <a href="#testimonials" className="expert-button">
                      {t("name_3")}
                    </a>
                    <p>{t("marketing_specialist")}</p>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </section>*/}

      <AccountSection backgroundImage={pricingbg} />
      <Footer />
    </>
  );
};

export default About;
