import {combineReducers} from 'redux'
import storage from 'redux-persist/lib/storage'
import {persistReducer} from 'redux-persist'

import Configs from './configs'

const persistConfigs = {
  key: 'Configs',
  storage,
}

const persistedConfigs = persistReducer(persistConfigs, Configs)

const AllReducers = combineReducers({
  configs: persistedConfigs,
})

export default AllReducers
