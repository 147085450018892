import axios from "axios";
import { GETAboutPageSECTIONSOne, GETAboutPageSECTIONSTwo, GetBannerDetail, GETHomWeWorkData, GETOurTeam } from "../endpoints";
const api = axios.create();

// export const getSectionOneDetail = async (id) => await api.get(`${GETAboutPageSECTIONSOne}?section_id=${id}`)
// export const getSectionTwoDetail = async (id) => await api.get(`${GETAboutPageSECTIONSTwo}?section_id=${id}`)

export const getBannerDetail = async (id) => await api.get(`${GetBannerDetail}?id=${id}`);
export const getHowWeWork = async (id) => await api.get(`${GETHomWeWorkData}`);
export const getTeamList = async (id) => await api.get(`${GETOurTeam}`);

export const getSectionOneDetail = async (id) => await api.get(`${GETAboutPageSECTIONSOne}?id=${id}`);
export const getSectionTwoDetail = async (id) => await api.get(`${GETAboutPageSECTIONSTwo}?id=${id}`);
// export const getSections = async () => await api.get(`${GETSECTIONS}`)
