import React, { useEffect, useState } from "react";

import { Header } from "../../components/Header/Header";
import Footer from "../../components/Footer";
// import {signup} from 'services/api/actions'

import { signup, subscription_list, googleSignIn, slider } from "../../services/api/actions";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { Form, Input, Row, Col, Button, Checkbox, Radio } from "antd";
import { toast } from "react-toastify";
import { ATTACH_PAYMENT_METHOD, GOOGLE_LOGIN, LOGIN } from "../../services/api/endpoints";

import { Link, useNavigate, useParams } from "react-router-dom";
import { LANGUAGE_TYPES } from "constants";
import { useTranslation } from "react-i18next";
import SignupImg from "../../assets/images/login-signup.png";
import googleIcon from "../../assets/logos/googleIcon.png";
import Twallam from "../../assets/logos/TwallamLogoUrdu.png";
import TwallamLogoEng from "../../assets/logos/TwallamHeaderEng.png";
import { useSelector } from "react-redux";
const api = axios.create();
// import { post } from "../api/index";

const Signup = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  let { type } = useParams();
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.configs);
  // const [value, setValue] = useState(type === 'sme' ? 1 : 2);
  const [value, setValue] = useState(1);
  const [loading, setLoading] = useState(false);

  console.log("window.location.origin", window.location.origin);

  const initialValues = {
    remember: true,
    // type: type === 'sme' ? 1 : 2
    type: 1,
  };
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
    localStorage.setItem("company", e.target.value);
  };

  useEffect(() => {
    localStorage.setItem("company", value);
  }, []);

  const googleLogin = async (data, googleResponse) => {
    console.log("google data", data);
    const company = localStorage.getItem("company");
    const payload = {};
    payload.data = data;
    payload.googleResponse = googleResponse;
    payload.company = company;
    // await api.post(GOOGLE_LOGIN,payload)

    try {
      googleSignIn(payload)
        .then((response) => {
          console.log("res.data", response);
          // navigate(`/pricing?sn=yes`);

          navigate(`/thankyou`);
        })
        .catch((error) => {
          console.log("errors here", error);
        });
    } catch (error) {
      console.error("Error package list issue:", error);
    }
  };

  const onFinish = async (values) => {
    console.log("Received values of form: ", values);
    console.log("in handleSubmit");
    try {
      // await form.validateFields();
      let formData = await form.validateFields();
      console.log("Received values of form: ", formData);

      formData.type = formData.type === 1 ? "company" : "individual";

      console.log("Received values of form after:  ", formData);

      try {
        setLoading(true);
        signup(formData)
          .then((response) => {
            setLoading(false);
            console.log("res.data", response);
            localStorage.setItem("user", JSON.stringify(response.data.data));
            // navigate(`/pricing?sn=yes`);

            if (response.data.meta.code === 404) {
              toast.error(response.data.meta.message);
            } else {
              if (localStorage.getItem("packages")) {
                if (localStorage.getItem("packages") == "free") {
                  navigate(`/thankyou`);
                } else {
                  // if already selected package
                  const product_id = localStorage.getItem("packages");
                  navigate(`/thankyou`);
                  //  navigate("/stripe", { state: { product_id } });
                }
              } else {
                // if not selected any package
                navigate(`/pricing?sn=yes`);
              }

              // navigate(`/send-otp`);
              // navigate(`/thankyou`);
            }

            // navigate(`/send-otp`);
          })
          .catch((error) => {
            setLoading(false);
            console.log("errors here", error);
          });
      } catch (error) {
        console.error("Error package list issue:", error);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  <Header />;

  return (
    <div className="Signup_form" dir={language === LANGUAGE_TYPES.en ? "ltr" : "rtl"}>
      <Row>
        <Col className="loginleft" xs={24} sm={24} md={13} lg={13} xl={13}>
          <div className="container form">
            <Form form={form} layout="vertical" autoComplete="off" name="SignUpForm" className="SignUp_form" initialValues={initialValues} onFinish={onFinish}>
              <div className="logocontainer">
                <Link to="/">
                  <img className="SignTwallamlogo" src={language === LANGUAGE_TYPES.en ? TwallamLogoEng : Twallam} alt="Twallam Logo" />
                </Link>
              </div>

              <div className="formHeading">
                <h4> {t("create_account")}</h4>
                <p>{t("enter_basic_details")}</p>
              </div>

              <Row gutter={[16, 16]}>
                <Col xs={22} sm={22} md={14} lg={14} xl={14}>
                  <div>
                    <Form.Item
                      label=""
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Password!",
                        },
                      ]}
                    >
                      <Radio.Group onChange={onChange} className="radioGroup" value={value}>
                        {/*<Radio.Group>*/}
                        {/*    <Radio    value={"company"}>Sign up as SME</Radio>*/}
                        <Radio value={1} className="radioButton">
                          {t("as_sme")}
                        </Radio>
                        <Radio value={2} className="radioButton">
                          {t("as_individual")}
                        </Radio>
                        {/*<Radio    value={1}>Sign up as SME</Radio>*/}
                        {/*<Radio value={"individual"}>Sign up as Individual </Radio>*/}
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                {/*<Col xs={22} sm={22} md={10} lg={11} xl={8}>*/}
                {/*    <div >*/}
                {/*        */}
                {/*    </div>*/}
                {/*</Col>*/}
                <Col span={12} xs={22} sm={22} md={16} lg={14} xl={14}>
                  {value === 1 && (
                    <div>
                      <Form.Item
                        name="company_name"
                        label={t("company_name")}
                        rules={[
                          {
                            required: true,
                            message: t("enter_your_company_name"),
                          },
                          {
                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                            message: "Company Name must start with an alphabet and contain only alphabets and spaces",
                          },
                        ]}
                      >
                        <Input placeholder={t("company_name")} />
                      </Form.Item>
                    </div>
                  )}

                  {value !== 1 && (
                    <div>
                      <Form.Item
                        name="company_name"
                        label={t("contact_name")}
                        rules={[
                          {
                            required: true,
                            message: t("enter_your_contact_name"),
                          },
                          {
                            pattern: /^[A-Za-z][A-Za-z\s]*$/,
                            message: "Contact Name must start with an alphabet and contain only alphabets and spaces",
                          },
                        ]}
                      >
                        <Input placeholder={t("contact_name")} />
                      </Form.Item>
                    </div>
                  )}
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={22} sm={22} md={16} lg={14} xl={14}>
                  <div>
                    <Form.Item
                      name="email"
                      label={t("email")}
                      rules={[
                        {
                          required: true,
                          message: t("enter_your_email"),
                        },
                        {
                          type: "email",
                          message: t("enter_your_email"),
                        },
                      ]}
                    >
                      <Input placeholder={t("enter_your_email")} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={22} sm={22} md={16} lg={14} xl={14}>
                  <div>
                    <Form.Item
                      name="password"
                      label={t("password")}
                      rules={[
                        {
                          required: true,
                          message: t("enter_your_password"),
                        },
                      ]}
                    >
                      <Input.Password className="LoginInput" type="password" placeholder={t("enter_your_password")} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={22} sm={22} md={16} lg={14} xl={14}>
                  <div>
                    <Form.Item
                      name="confirm_password"
                      label={t("confirm_password")}
                      rules={[
                        {
                          required: true,
                          message: t("enter_your_confirm_password"),
                        },
                      ]}
                    >
                      <Input.Password className="LoginInput" type="password" placeholder={t("enter_your_confirm_password")} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={22} sm={22} d={16} lg={14} xl={14}>
                  <div>
                    <Form.Item>
                      <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>{t("agree_terms")}</Checkbox>
                      </Form.Item>

                      {/*<a className="login-form-forgot" href="/login">*/}
                      {/*    Don’t have an account ? <span>Sign In</span>*/}
                      {/*</a>*/}
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={22} sm={22} md={16} lg={14} xl={14}>
                  <Form.Item>
                    <div className="Signupbtn">
                      <Button className="" disabled={loading} type="primary" htmlType="submit">
                        {loading ? (
                          <span className="indicator-progress" style={{ display: "block" }}>
                            {t("please_wait")}
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        ) : (
                          <span className="indicator-label">{t("sign_up")}</span>
                        )}
                      </Button>
                    </div>

                    <h2 className="line">
                      <span className="lineText">
                        <span className="spantext">{t("or")} </span>
                        {t("sign_up_with")} <span className="spantext"> google </span>
                      </span>
                    </h2>

                    <div id="buttonDiv" className="GoogleLoginBtn">
                      <GoogleLogin
                        data-width="300"
                        data-height="200"
                        onSuccess={(credentialResponse) => {
                          console.log(credentialResponse);

                          const token = credentialResponse.credential;
                          const decoded = jwtDecode(token);

                          // console.log(decoded,'decodedd');
                          googleLogin(decoded, credentialResponse);
                        }}
                        onError={() => {
                          console.log("Login Failed");
                        }}
                        type="standard" // Set type to standard
                        size="large" // Set size to large
                        shape="rectangular" // Set shape to rectangular
                        width="490"
                      />
                    </div>

                    {/*  <Button type="button" className="SignupbtnGoogle" onClick={() => login()}>*/}
                    {/*  <img src={googleIcon} alt="" srcset="" />{" "}*/}
                    {/*  <span>Sign up with Google</span>*/}
                    {/*</Button>*/}
                    <p className="SignInLink">
                      {t("already_have_account")}{" "}
                      <Link target="_blank" type="button" to="https://admin.twallam.com/login">
                        {t("sign_in")}
                      </Link>
                    </p>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>

        <Col className="loginright" xs={24} sm={24} md={11} lg={11} xl={11}>
          <div className="container">
            <div className="bottom-Image">
              <img src={SignupImg} alt={"logo"} className="image-class" style={{ height: "auto" }} />
            </div>

            <p className="signupFooter">
              {t("2024")} © <span className="footerspantext">{t("Twallam")}.</span> {t("powered_by")} softech-system
            </p>
          </div>
        </Col>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}></Row>
      </Row>
    </div>
  );
};

export default Signup;
