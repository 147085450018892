import React, { useState } from 'react'

import { Header } from '../../components/Header/Header'
import Footer from '../../components/Footer'
import configs from '../../configs/index'
import { getAdminUrl } from "helpers";
import {useSelector} from 'react-redux'
import {LANGUAGE_TYPES} from "constants";


const PrivacyPolicy = () => {
    // const adminApiUrl=configs.adminApiUrl;
    const adminApiUrl= getAdminUrl();
    const {language} = useSelector((state) => state.configs)

    return (
        <div>
            <Header />
            <div className="container form privacyPolicy" >

                {/* For English content */}
                {
                    language === LANGUAGE_TYPES.en ?

                        <>

                <p>
                    Terms and Conditions for the Use of Hesabat & Amaal Financial Technology Company's Services
                    These terms and conditions represent a formal agreement ("Contract") between Hesabat & Amaal Financial Technology Company ("Company") and users of the service. These terms govern your use of the Company’s service, which is an electronic accounting system that provides clients and users with the ability to record and store their accounting data for use in the form of invoices or reports.
                    Any use by you of the services provided by the Company’s accounting system constitutes your acceptance of this contract and its terms. Consequently, you should not use the system if you do not agree to the terms and conditions set forth in this contract.
                    The Company reserves the right to modify or change these terms and conditions without prior notice. It is your responsibility as a user of the system to periodically review the terms and conditions to be aware of any updates that may occur. We also recommend reviewing our Privacy Policy to learn more about how the Company uses the information provided by system users.
                </p>
                <h5>Service Description</h5>
             <p>
                 Twallam is an accounting and financial technology service provider that offers accounting services via accounting software. Our services include, but are not limited to, managing financial data, automating accounting processes, using software tools to enhance accuracy and efficiency, recording and registering financial transactions for companies, providing financial and accounting consultations, and offering financial technology and accounting software.
                 Eligibility for Services
                 You represent and warrant that:
             </p>
            <ol>

                <li>You have never been prohibited from using the Company’s services or had your use of the services disabled at any time.</li>
                <li>You are not a competitor of the Company, nor do you offer any products that compete with the services provided by the Company.</li>
                <li>You have the full power and authority to enter into this contract and that doing so does not violate any law or contract.</li>
            </ol>



                <h5>Representations and Warranties</h5>
                <p>You represent and warrant that you will:</p>
            <ol>
                <li>Comply with all applicable laws and regulations in the Kingdom of Saudi Arabia.</li>
                <li>Provide accurate and correct information to the Company and update it periodically.</li>
                <li>Review and comply with any notices sent by the Company or posted on the Company’s platforms regarding your use of the services provided by the Company’s system.</li>
                <li>Not sublicense, issue, publish, transmit, distribute, perform, display, sell, or reclassify the Company’s services, otherwise you will be deemed to have transferred or commercially exploited the service, except as permitted under this contract.</li>
                <li>Use the service only for lawful purposes, and not use the services to send or store any unlawful materials or for fraudulent purposes.</li>
                <li>Not use the service to cause harm, harassment, or inconvenience to anyone.</li>
                <li>Not disrupt the proper operation of the Company’s system.</li>
                <li>Not attempt to damage the service in any way.</li>
                <li>Not copy or distribute the system or other content without obtaining written permission from the Company.</li>
                <li>Keep your account password or any identification means provided to you for accessing your account secure and confidential.</li>
                <li>Provide us with all evidence that proves your identity according to the Company’s sole discretion.</li>
                <li>The Company reserves the right to refuse to provide the service or use the system without giving reasons.</li>
            </ol>
                <h5>
                    Scope of License
                </h5>
                <p>The Company grants you a non-exclusive, non-transferable, non-assignable, non-sublicensable, and revocable license to access and use our services solely for personal purposes under this contract. Therefore, you must:</p>

            <ol>
                <li>Not make the services available or rent, lease, assign, resell, distribute, or sublicense these services to any third party.</li>
                <li>Not modify, disassemble, translate, summarize, or create derivative works based on the Company’s system design or otherwise determine or attempt to determine or attempt to access the source code or internal design of the services or any text or multimedia (images, audio, video files), or data or other information provided by the Company or third-party service providers.</li>
                <li>Not delete, alter, or otherwise modify any copyright or other official notices contained in the services.</li>
                <li>Not intentionally transmit or distribute the services or allow the services to be downloaded for use other than as specified herein.</li>
                <li>Not claim to grant the services or allow access to them to anyone other than yourself.</li>
                <li>Not allow sharing of your username/password or other access credentials for the Company’s services.</li>
            </ol>
                <h5>Intellectual Property Rights</h5>
                <p>All intellectual property rights in these services and all related materials or appearing on them (including any content you submit or incorporate) are owned by the Company. You must not reproduce or allow anyone – for any reason – to use or reproduce the services or any trademarks or other trade names that appear in the services.</p>
                <h5>Payment</h5>
                <p>The Company reserves the right to impose new fees for using the service. If the Company decides to impose new fees, you will be informed, and you will be allowed to continue the contract or terminate it.</p>
                <p>The Company may modify or update prices from time to time. It is your responsibility to stay informed of the current prices for the services provided through the pricing page, which can be accessed from the home page.</p>
                <p>You must pay for the services to the Company by credit cards or electronic wallets to be provided with the service. You alone are responsible for paying all fees on time, and you acknowledge that any amount paid is non-refundable.</p>
               <p> By agreeing to these terms of use, you agree that your right to access the data stored in the Company’s system is linked to the status of your account being active and your subscription being paid. Failure to pay the amounts due will result in your access to the service and the stored data being suspended.</p>
                <p>If you do not pay the due subscription fee for the Company’s system, the Company will grant you a grace period of up to ten days before suspending your access to the service and the stored data, provided that this grace period will be invoiced by the Company’s service and paid by you as a user.</p>
                <h5>Indemnification</h5>
                <p>By agreeing to these terms of use and using the service, you agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and each of their officers, directors, other users, employees, attorneys, and agents from and against any claims, costs, damages, losses, liabilities, and expenses (including attorney’s fees and costs) arising out of or related to:</p>
                <ol>
                    <li>Your violation or breach of any term of these terms of use or any applicable law or regulation, whether or not referenced in these terms and conditions.</li>
                    <li>Your violation of any rights of any third party, including service providers who arrange services through the Company.</li>
                    <li>Your misuse of the service.</li>
                </ol>
                <h5>Legal Liability</h5>
               <p> The information, recommendations, and services, or any of them provided to you on or through the website and service are for general informational purposes only and do not constitute any advice. The Company will maintain, to the best of its ability, the accuracy and updating of the website and service and its contents, but it does not guarantee that the (contents) of the website or service are free from errors, defects, malware, or viruses, nor does it guarantee the accuracy, correctness, and updating of the website and service.</p>
                <p>The Company is not responsible for any damages resulting from the use (or inability to use) of the website or service, including damages caused by malware or viruses, nor is it responsible for the inaccuracy or incompleteness of the information or the website or service, unless such damage results from intentional misconduct or gross negligence by the Company.</p>
                <p>The Company is also not responsible for any damages resulting from the use (or inability to use) of electronic means of communication with the website or service, including – but not limited to – damages resulting from non-delivery or delay in delivery of electronic communications or interception or manipulation of electronic communications by third parties or by computer programs used for electronic communication and virus transmission.</p>
                <p>The Company strives to provide its electronic services around the year 24/7; however, it does not guarantee that the Company’s system will operate fully throughout the year, and there will be stoppages for maintenance, periodic updates, or adding new features to the service.</p>
                <h5>Modification of Services</h5>
                <p>The Company reserves the right, at its sole discretion, to at any time:</p>

            <ol>
                <li>Change the services or any materials related to them and/or discontinue the publication of its services.</li>
                <li>If the Company decides to discontinue publishing its services, it may at its sole discretion replace the services with other similar materials.</li>
            </ol>
            <h5>Limitation of Liability</h5>
            <p>The Company shall not be liable for any indirect, incidental, special, or consequential damages arising out of or related to your use of the service, including, but not limited to, damages resulting from the loss of data or interruption of service.</p>
                <h5>Term and Termination of the Contract</h5>
                <p>The contract between you and the Company is of indefinite duration. You may terminate the contract at any time by deleting your account from the system and ceasing to use the service. The Company reserves the right to terminate the contract between you and it at any time without prior notice in the event of your violation of any term of these terms of use.</p>
                <h5>Notices</h5>
                <p>The Company may direct a notice by sending an email to your email address registered in your account with the Company. Therefore, you should monitor your email and keep it updated regularly.</p>
                <h5>Governing Law and Dispute Resolution</h5>
                <p>These terms of use are subject to and interpreted in accordance with the laws and regulations applicable in the Kingdom of Saudi Arabia.</p>


                {/*<h1 className="FormTitle text-center">Thank You</h1>*/}
                {/*<p className="FormTitle text-center">Thank you for the subscription, please <a href={adminApiUrl} target="_blank" rel="noopener noreferrer">Click here</a> to login your account.</p>*/}


                        </>
                        :
                        <>
                {/* For Arabic content */}


                <h5 className='ar-right'>
                    شروط وأحكام استخدام خدمة شركة حسابات وأعمال للتقنية المالية
                </h5>
                            <p className='ar-right ar-text-align'>
                                تمثل هذه الشروط والأحكام اتفاقًا رسميًا ("عقد") بين شركة حسابات وأعمال للتقنية المالية ("الشركة") ومستخدمي الخدمة. تحكم شروط المستخدم هذه استخدامكم لخدمة الشركة، والتي هي عبارة عن نظام محاسبي إلكتروني يوفر للعملاء والمستخدمين تسجيل وحفظ بياناتهم المحاسبية واستخدامها في شكل فواتير أو تقارير.
                            </p>
                            <p className='ar-right ar-text-align'>
                            إن أي استخدام من قبلك للخدمات التي يوفرها نظام الشركة المحاسبي يشكل موافقة منك على هذا العقد وأحكامه، وتبعاً لذلك يجب عليك عدم استخدام النظام في حال لم تكن موافقاً على الأحكام والشروط الواردة في هذا العقد.
                            </p>

                            <p className='ar-right ar-text-align'>
                                تحتفظ الشركة بحق تعديل أو تغيير هذه الأحكام والشروط دون إخطار مسبق، ويكون من مسؤوليتك كمستخدم للنظام مراجعة ضوابط وشروط الاستخدام بشكل دوري لمعرفة التحديثات التي تطرأ على هذه الأحكام والشروط، كما نأمل مراجعة سياسة الخصوصية لدينا لمعرفة المزيد حول كيفية استخدام الشركة للمعلومات التي تقدم لنا من قبل مستخدمي النظام.
                            </p>
                <h5 className='ar-right'>
                    وصف الخدمة
                </h5>
                            <p className='ar-right ar-text-align'>
                                تولم هي شركة خدمات محاسبية وتقنية مالية تقدم خدمات محاسبية بوساطة برامج المحاسبة. تشمل خدماتنا، ولكن لا تقتصر على ذلك، إدارة البيانات المالية، وتلقائية عمليات المحاسبة، واستخدام أدوات برمجية لتعزيز الدقة والكفاءة، تقييد وتسجيل الحركات المالية للشركات، تقديم الاستشارات المالية والمحاسبية، توفير برمجيات التقنية المالية والبرمجيات المحاسبية.
                            </p>
                            <div>
                <h5 className='ar-right ar-heading-style'>
                    أحقية الحصول على الخدمات
                </h5>

                            <p className='ar-right ar-text-align ar-heading-style'>
                                :أنت تقر وتضمن التالي
                            </p>


                            <ol className='ar-right ar-ol-padding' dir='rtl'>
                                <li >	أنه لم يسبق أن تم تعطيل استخدامك لخدمات الشركة أو منعك من استخدامها في أي وقت من الأوقات.</li>
                                <li >	أنه لم يسبق أن تم تعطيل استخدامك لخدمات الشركة أو منعك من استخدامها في أي وقت من الأوقات.</li>
                                <li >	أنه لم يسبق أن تم تعطيل استخدامك لخدمات الشركة أو منعك من استخدامها في أي وقت من الأوقات.</li>
                            </ol>
                            </div>

                            <div>
                                <h5 className='ar-right ar-heading-style'>
                                    أالتعهدات والضمانات
                                </h5>

                                <p className='ar-right ar-text-align ar-heading-style'>
                                    :أنت تقر وتضمن بأنك سوف
                                </p>


                                <ol className='ar-right ar-ol-padding' dir='rtl'>
                                    <li >	تمتثل لكافة القوانين واللوائح المعمول بها في المملكة العربية السعودية</li>
                                    <li >	تقدم معلومات صحيحة ودقيقة إلى الشركة وتقوم بتحديثها بشكل دوري.</li>
                                    <li >	تُراجع وتمتثل لأي إشعارات يتم إرسالها من خلال الشركة أو ذكرها في منصات الشركة فيما يتعلق باستخدامك للخدمات المقدمة من نظام الشركة</li>
                                    <li >	لن تقوم بتكرار تراخيص من الباطن، أو إصدار، أو نشر، أو نقل، أو توزيع، أو تنفيذ، أو عرض، أو بيع، أو إعادة تصنيف خدمات الشركة، وإلا فإنك تكون قد نقلت الخدمة أو قمت باستغلالها تجاريًا، باستثناء ما يسمح به بموجب هذا العقد.</li>
                            <li>سوف تستخدم الخدمة لأغراض مشروعة فقط، ولن تستخدم الخدمات لإرسال أو تخزين أي مواد غير قانونية أو بهدف الاحتيال.</li>
                              <li>	لن تستخدم الخدمة للتسبب بإيذاء أو مضايقة أو إزعاج أحد ما</li>
                                    <li>.	لن تعرقل التشغيل السليم لنظام الشركة</li>
                                    <li>	لن تحاول إلحاق الضرر بالخدمة بأي شكل من الأشكال.</li>
                                    <li>	لن تنسخ أو توزع النظام أو المحتويات الأخرى دون الحصول على إذن كتابي من الشركة.</li>
                                    <li>سوف تحافظ على كلمة المرور لحسابك أو أي وسيلة تعريف نقدمها لك وتتيح الدخول إلى حسابك، بشكلٍ آمن وسري.</li>
                                    <li>	سوف تقدم لنا كافة الدلائل التي تثبت هويتك وفقًا لتقدير الشركة الخاص.</li>
                                    <li>	يحق للشركة رفض تقديم الخدمة أو استخدام النظام دون إبداء أسباب.</li>
                                </ol>
                            </div>


                            <div>
                                <h5 className='ar-right ar-heading-style'>
                                    نطاق الترخيص
                                </h5>

                                <p className='ar-right ar-text-align ar-heading-style'>
                                    تمنحك الشركة ترخيصًا غير حصري، غير قابل للتحويل، غير قابل للتنازل، غير قابل للترخيص من الباطن وغير قابل للإلغاء وذلك للحصول على خدماتنا واستخدامها فقط للغرض الشخصي المحدد وفقًا لهذا العقد، لذا يجب عليك
                                </p>


                                <ol className='ar-right ar-ol-padding' dir='rtl'>
                                  <li>	عدم إتاحة الخدمات أو تأجير، أو استئجار أو تخصيص أو إعادة بيع، أو توزيع أو ترخيص من الباطن لهذه الخدمات إلى أي طرف ثالث.</li>
                                  <li>عدم تعديل، أو اجتزاء، أو ترجمة، أو تلخيص، أو إنشاء عمل فرعي يقوم على فك، أو تنفيذ هندسة عكسية لتصميم نظام الشركة أو خلاف ذلك من تحديد أو محاولة تحديد أو محاولة الوصول إلى شفرة المصدر أو التصميم الداخلي للخدمات أو أي نص أو الوسائط المتعددة (صور، صوتيات، ملفات فيديو)، أو البيانات أو غيرها من المعلومات التي تقدمها الشركة أو الطرف الثالث من مقدمي الخدمة.</li>
                                  <li>عدم حذف، أو تغيير، أو غير ذلك من التعديل على أي حق من حقوق النشر أو غيرها من الإخطارات الرسمية الواردة في الخدمات.</li>
                                  <li>عدم تعمد نقل، أو توزيع الخدمات، أو السماح بتحميل الخدمات للاستخدام بخلاف ما هو محدد هنا.</li>
                                  <li>عدم الادعاء بمنح الخدمات أو السماح بالوصول إليها لأي شخص غيرك.</li>
                                  <li>عدم السماح بمشاركة اسم المستخدم/ أو كلمة المرور أو غيرها من مسوغات الوصول إلى خدمات الشركة.</li>
                                </ol>
                            </div>


                            <div>
                                <h5 className='ar-right ar-heading-style'>
                                    حقوق الملكية الفكرية
                                </h5>

                                <p className='ar-right ar-text-align ar-heading-style'>
                                    جميع حقوق الملكية الفكرية لهذه الخدمات وكافة المواد المتعلقة بها أو التي تظهر عليها (وتشمل أي محتوى تقوم بتقديمه أو بإدراجه) هي ملكية للشركة. ويجب عليك ألا تقوم بإعادة إنتاج أو السماح لأي شخص – لأي سبب من الأسباب – باستخدام أو إعادة إنتاج الخدمات أو أي علامات تجارية أو غيرها من الأسماء التجارية التي تظهر في الخدمات.
                                </p>
                            </div>

                            <div>
                                <h5 className='ar-right ar-heading-style'>
                                    الدفع
                                </h5>

                                <p className='ar-right ar-text-align ar-heading-style'>
                                    تحتفظ الشركة لنفسها بالحق في فرض رسوم جديدة على استخدام الخدمة. إذا قررت الشركة فرض رسوم جديدة، فإنه سيتم إفادتك بذلك وسيتم السماح لك بالاستمرار في العقد أو إنهائه.
                                </p>

                                <p className='ar-right ar-text-align ar-heading-style'>
                                    يجوز للشركة تعديل أو تحديث الأسعار من وقت لآخر. ويقع على عاتقك مسؤولية البقاء على معرفة بالأسعار الحالية للخدمات المقدمة من خلال صفحة الأسعار ويمكن الوصول لها من خلال الصفحة الرئيسية.
                                </p>
                                <p className='ar-right ar-text-align ar-heading-style'>
                                    يتوجب عليك دفع قيمة الخدمات إلى الشركة عن طريق البطاقات الائتمانية او المحافظ الالكترونية، حتى يتم توفير الخدمة لك. وتتحمل وحدك مسؤولية دفع جميع الرسوم في موعدها وتقر بأن أي مبلغ يُدفع لا يمكن استرداده.
                                </p>
                                <p className='ar-right ar-text-align ar-heading-style'>
                                    بموافقتك على شروط المستخدم الماثلة، فإنك توافق على أن أحقية دخولك على البيانات المحفوظة في نظام الشركة مرتبط بكون حسابك نشطًا واشتراكك مدفوع القيمة، وأن عدم دفع المبالغ المستحقة عليك يترتب عليه حجب استخدامك للخدمة والبيانات المحفوظة عليها.
                                </p>
                                <p className='ar-right ar-text-align ar-heading-style'>
                                    في حال عدم قيامك بدفع قيمة الاشتراك المستحق في نظام الشركة، فإن الشركة ستمنحك مهلة للسداد أقصاها عشرة أيام قبل قيامها بحجب استخدامك للخدمة والبيانات المحفوظة عليها، على أن يتم فوترة هذه المهلة من قبل خدمة الشركة ودفعها من قبلك كمستخدم.
                                </p>
                            </div>


                            <div>
                                <h5 className='ar-right ar-heading-style'>
                                    التعويض
                                </h5>

                                <p className='ar-right ar-text-align ar-heading-style'>
                                    بموافقتك على شروط المستخدم الماثلة واستخدام الخدمة، فإنك توافق على الدفاع عن الشركة والشركات التابعة لها والجهات المرخِّصة لها وكل مسؤول من مسؤوليها ومديريها وغيرهم من المستخدمين والموظفين والمحامين والوكلاء وعدم إلحاق الضرر بهم وإبراء ذمتهم من أي مطالبات، تكاليف، أضرار، خسائر، مسؤوليات ومصروفات (بما في ذلك أتعاب وتكاليف المحاماة) والتي تنشأ عن أو ترتبط بما يلي:
                                </p>


                                <ol className='ar-right ar-ol-padding' dir='rtl'>
              <li>	انتهاكك أو مخالفتك لأي شرط من شروط المستخدم الماثلة هذه أو لأي قانون أو لوائح معمول بها، سواء أشير إليها في شروط وأحكام الاستخدام هذه أم لا.</li>
              <li>انتهاكك لأي حقوق تخص الغير، بما في ذلك مقدمي الخدمات الذين ينظمهم خدمة الشركة.</li>
              <li>	إساءة استخدامك للخدمة.</li>
                                </ol>
                            </div>


                            <div>
                                <h5 className='ar-right ar-heading-style'>
                                    المسؤولية القانونية
                                </h5>

                                <p className='ar-right ar-text-align ar-heading-style'>
                                    المعلومات والتوصيات والخدمات أو أي منها التي قُدمت لك على أو من خلال موقع الويب والخدمة ما هي إلا لأغراب المعلومات العامة فقط ولا تمثل أي نصيحة. ستحافظ الشركة قدر الإمكان على صحة وتحديث الموقع والخدمة ومحتوياته، لكنها لا تضمن أن (محتويات) الموقع أو الخدمة خالية من الأخطاء أو العيوب أو البرامج الضارة والفيروسات ولا تضمن صحة ودقة وتحديث موقع الويب والخدمة.
                                </p>
                                <p className='ar-right ar-text-align ar-heading-style'>
                                    لا تتحمل الشركة المسؤولية عن أي أضرار تنتج عن استخدام (أو عدم القدرة على استخدام) الموقع أو الخدمة، بما في ذلك الأضرار التي تسببها البرامج الضارة أو الفيروسات، كما لا تتحمل مسؤولية عدم صحة أو عدم اكتمال المعلومات أو موقع الويب أو الخدمة، ما لم يكن هذا الضرر ناتجًا عن سوء سلوك عمدي أو عن إهمال جسيم من جانب الشركة.
                                </p>
                                <p className='ar-right ar-text-align ar-heading-style'>
                                    كما لا تتحمل الشركة أي مسؤولية عن أي أضرار تنتج عن استخدام (أو عدم القدرة على استخدام) وسائل اتصال إلكترونية مع الموقع الإلكتروني أو مع الخدمة، بما في ذلك – على سبيل المثال لا الحصر – الأضرار الناتجة عن عدم تسليم المخاطبات الإلكترونية أو التأخر في تسليمها أو اعتراضها أو التلاعب في المخاطبات الإلكترونية من جانب الغير أو من جانب برامج الكمبيوتر المستخدمة للمخاطبات الإلكترونية ونقل الفيروسات.
                                </p>
                                <p className='ar-right ar-text-align ar-heading-style'>
                                    تسعى الشركة قدر الإمكان لتوفير خدماتها الإلكترونية على مدار العام 24 ساعة/7 أيام في الأسبوع، وفي جميع الأحوال، فإنها لا تضمن عمل نظام الشركة بشكل كامل طوال العام، وأنه سيكون هناك توقفات لغرض الصيانة أو التحديث الدوري أو إضافة خواص جديدة على الخدمة.
                            </p>

                            </div>

                            <div>
                                <h5 className='ar-right ar-heading-style'>
                                    تعديل الخدمات
                                </h5>

                                <p className='ar-right ar-text-align ar-heading-style'>
                                    تحتفظ الشركة بحقها، وبإرادتها المنفردة، بأن تقوم في أي وقت بالآتي:
                                </p>

                                <ol className='ar-right ar-ol-padding' dir='rtl'>
                             <li>	تغيير الخدمات أو أي مواد مرتبطة بها و/أو إيقاف نشر خدماتها.</li>
                             <li>	إذا قررت الشركة إيقاف نشر خدماتها، فإنها قد تقوم بإرادتها باستبدال الخدمات بمواد أخرى مشابهة.</li>
                                </ol>

                            </div>


                            <div>
                                <h5 className='ar-right ar-heading-style'>
                                    حدود المسؤولية
                                </h5>

                                <p className='ar-right ar-text-align ar-heading-style'>
                                    الشركة لن تكون مسؤولة عن أي أضرار غير مباشرة أو عرضية أو خاصة أو تبعية تنشأ عن أو تتعلق باستخدامك للخدمة، بما في ذلك، على سبيل المثال لا الحصر، الأضرار الناجمة عن فقدان البيانات أو توقف الخدمة.
                                </p>

                            </div>

                            <div>
                                <h5 className='ar-right ar-heading-style'>
                                    مدة العقد وإنهاؤه
                                </h5>

                                <p className='ar-right ar-text-align ar-heading-style'>
                                    يُعد العقد المبرم بينك وبين الشركة غير محدد المدة. ويحق لك إنهاء العقد في أي وقت بحذف حسابك من النظام وإيقاف استخدام الخدمة. يحق للشركة إنهاء العقد المبرم بينك وبينها في أي وقت دون إشعار مسبق في حال انتهاكك لأي شرط من شروط المستخدم هذه.
                                </p>

                            </div>

                            <div>
                                <h5 className='ar-right ar-heading-style'>
                                    الإخطارات
                                </h5>

                                <p className='ar-right ar-text-align ar-heading-style'>
                                    قد توجه الشركة إخطارًا بواسطة إرسال بريد إلكتروني إلى عنوانك البريدي المسجل في حسابك لدى الشركة، وتبعاً لذلك يجب عليك متابعة بريدك الإلكتروني وتحديثه باستمرار.
                                </p>

                            </div>
                            <div>
                                <h5 className='ar-right ar-heading-style'>
                                    القانون الساري وحل النزاعات
                                </h5>

                                <p className='ar-right ar-text-align ar-heading-style'>
                                    تخضع شروط المستخدم هذه للقوانين والأنظمة المطبقة في المملكة العربية السعودية وتُفسر وفقاً لها.
                                </p>

                            </div>
                        </>
                }
            </div>
            {/*<br/>*/}
            {/*<br/>*/}
            {/*<br/>*/}
            {/*<br/>*/}
            {/*<br/>*/}
            {/*<br/>*/}
            {/*<br/>*/}
            {/* <div className={`${language === LANGUAGE_TYPES.ar ? 'top-margin' : 'ar-lang-style'}`}></div> */}
            < Footer />
        </div>
    )
}

export default PrivacyPolicy