import React, { useState, useEffect } from "react";
import { Tabs, Row, Col } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import { ocrSteps } from "../services/api/actions/homeOcrSteps";
import { LANGUAGE_TYPES } from "constants";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

const OcrSection = () => {
  const [ocrStep, setOcrSteps] = useState([]);
  const { language } = useSelector((state) => state.configs);
  const { t } = useTranslation();

  useEffect(() => {
    try {
      ocrSteps({})
        .then((response) => {
          console.log("response ocr", response);
          setOcrSteps(response.data.data);
        })
        .catch((error) => {
          console.log("errors here response");
        });
    } catch (error) {
      console.error("Error ocr steps list issue:", error);
    }
  }, []);

  return (
    <div className={`container ocrsection ${language === LANGUAGE_TYPES.ar ? "rtl" : "ltr"}`}>
      <div className="ocrheader">
        <div className="SectionHeadingcontent">
          <p className="">{t("Simple Steps for Successful Financial Management")}</p>
          <h1 className="">
            {t("The First")} <span className="textcolorgreen">{t("AI-Powered")}</span> {t("Financial")} <br /> {t("Management Software")}.
          </h1>
        </div>
      </div>

      <Tabs defaultActiveKey="1" onChange={(key) => console.log(key)} className="custom-tab-label">
        {ocrStep.map((step, index) => {
          let sentence = step && language === LANGUAGE_TYPES.en ? step.title : step.title_ar;
          let long_description = step && language === LANGUAGE_TYPES.en ? step.long_description : step.long_description_ar;
          let words = sentence.split(" ");
          const isLastStep = index === ocrStep.length - 1;
          return (
            <TabPane
              tab={
                <div className="tabLabel">
                  <div className="labelcontainer">
                    <div className="labelNo"> {t(index + 1)}</div>
                    <div className="labeltext">
                      <p className="multiline-text">
                        {words.map((word, inner_index) => {
                          const breakAfter = isLastStep ? 3 : 4;
                          // return   (
                          //
                          //         inner_index === 3 ? <br/>  : `${word} `
                          //
                          // )

                          return (
                            <>
                              {word}
                              {inner_index === breakAfter - 1 && <br />}
                              {inner_index < words.length - 1 && " "}
                            </>
                          );
                        })}
                      </p>
                    </div>
                  </div>
                  {index !== ocrStep.length - 1 && <ArrowUpOutlined className={`ocrStepArrow ${language === LANGUAGE_TYPES.ar ? "rotate-arrow" : ""}`} />}
                </div>
              }
              key={index.toString()}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <div className="imgcontainer">
                    <img src={step.image} alt={"logo"} className="OcrImages" style={{ height: "auto" }} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="tabText">
                    <h1>{sentence}</h1>
                    <p>{long_description}</p>
                  </div>
                </Col>
              </Row>
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default OcrSection;
