import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Button, TimePicker, DatePicker } from "antd";
import { toast } from "react-toastify";
import ContactFormLogo from "../assets/logos/TwallamLogo.png";
import { getSections, contactUs } from "../services/api/actions";
import { useSelector } from "react-redux";
import { LANGUAGE_TYPES } from "constants";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import TwallamLogo from "../assets/logos/ContactTwallamLogo.png";
import TwallamLogoEng from "../assets/logos/ContactTwallamLogoEng.png";

const ContactUsSection = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [sectionFour, setSectionFourDetail] = useState("");
  const { language } = useSelector((state) => state.configs);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  let title = sectionFour && language === LANGUAGE_TYPES.en ? sectionFour.title : sectionFour.title_ar;
  let name = sectionFour && language === LANGUAGE_TYPES.en ? sectionFour.name : sectionFour.name_ar;
  let long_description = sectionFour && language === LANGUAGE_TYPES.en ? sectionFour.long_description : sectionFour.long_description_ar;

  console.log("titletitle", title);
  if (title === undefined) {
    title = "";
  }

  console.log("title n", title);

  // let title = sectionFour.title ?? "";
  title = title.split(" ");

  useEffect(() => {
    getSectionDetails();
  }, []);

  const getSectionDetails = () => {
    getSections()
      .then((res) => {
        const { data } = res;
        console.log("data", data.data);
        setSectionFourDetail(data.data[3]);
        // setSectionTwoDetail(data.data[1])
      })
      .catch((error) => {});
  };
  const onFinish = async (values) => {
    console.log("Received values of form: ", values);
    console.log("in handleSubmit");
    try {
      // await form.validateFields();
      const formData = await form.validateFields();
      console.log("Received values of form: ", formData);
      // ----------------------------------------

      try {
        setLoading(true);
        contactUs(formData)
          .then((response) => {
            setLoading(false);
            console.log("contact us res.data", response);

            if (response.data.meta.code === 404) {
              toast.error(response.data.meta.message);
            } else {
              navigate(`/thankyou?redirect=contact`);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log("errors here", error);
          });
      } catch (error) {
        console.error("Error sent mail:", error);
      }

      // ----------------------------------------

      // const newData = {

      // };

      // await post("signUp", newData);
      form.resetFields();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  return (
    <>
      <section id="ContactUsSection">
        <div className="ContactUsSection container" dir={language === LANGUAGE_TYPES.en ? "ltr" : "rtl"}>
          <div className="ContactUsSection-content">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={11} lg={12} xl={12}>
                <div className="leftcolcontent">
                  <p className="headingtext">{name}</p>
                  {/*<h1>*/}

                  {/*  {sectionFour.title}*/}
                  {/*To Get Your <br />*/}
                  {/*<span className="textcolorgreen">Business</span>*/}
                  {/*<br />*/}
                  {/*To The Next <br />*/}
                  {/*Level*/}
                  {/*</h1>*/}

                  {title.length > 0 && (
                    <h1>
                      {title.map((word, index) =>
                        index === 4 ? (
                          <span className={`${index === 4 ? "textcolorgreen" : ""}`}>
                            {word}&nbsp;
                            {index === 3 ? <br /> : ""}
                          </span>
                        ) : (
                          `${word}  `
                        )
                      )}
                      &nbsp;
                    </h1>
                  )}
                  <p>{long_description}</p>
                  <div className="imgcontainer">

                    {/*<img src={`${process.env.REACT_APP_API_URL}/public/uploads/${sectionFour.image}`} alt={"logo"} className="ContactFormLogo" style={{ height: "auto" }} />*/}
                    <img src={`${language === LANGUAGE_TYPES.en ? TwallamLogoEng : TwallamLogo}`} alt={"logo"} className="ContactFormLogo" style={{ height: "auto" }} />
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                <div className=""></div>
              </Col>
              <Col xs={24} sm={24} md={11} lg={10} xl={10}>
                <h1 className="contactformheading">
                  {t("Get_Free_Business")} <br />
                  {t("Appointment")}
                </h1>
                <Form
                  form={form}
                  layout="vertical"
                  autoComplete="off"
                  name="ContactUsForm"
                  className="ContactUsForm"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                >
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                      <div style={{ padding: "8px 0" }}>
                        <Form.Item
                          name="userName"
                          rules={[
                            {
                              required: true,
                              message: t("enter_your_name"),
                            },
                            {
                              pattern: /^[A-Za-z][A-Za-z\s]*$/,
                              message: "Name must start with an alphabet and contain only alphabets and spaces",
                            },
                          ]}
                        >
                          <Input placeholder={t("your_name")} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                      <div style={{ padding: "8px 0" }}>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: t("enter_your_email"),
                            },
                            {
                              type: "email",
                              message: "Please enter a valid email",
                            },
                          ]}
                        >
                          <Input placeholder={t("form_email_address")} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                      <div style={{ padding: "8px 0" }}>
                        <Form.Item
                          label=""
                          name="mobile"
                          rules={[
                            {
                              required: true,
                              message: t("enter_your_number"),
                            },
                            {
                              pattern: /^[0-9]{11}$/,
                              message: "Please enter a valid 11-digit mobile number",
                            },
                          ]}
                        >
                          <Input placeholder={t("form_phone_number")} maxLength={11} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={22} lg={11} xl={11}>
                      <div style={{ padding: "8px 0" }}>
                        <Form.Item className="date" label="" name="date" rules={[{ required: true, message: t("select_date") }]}>
                          <DatePicker placeholder={t("date")} onChange={onChange} />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={22} lg={11} xl={11}>
                      <div style={{ padding: "8px 0" }}>
                        <Form.Item
                          className="time"
                          name="time"
                          rules={[
                            {
                              required: true,
                              message: t("select_time"),
                            },
                          ]}
                        >
                          <TimePicker placeholder={t("time")} use12Hours format="h:mm a" />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                      <div style={{ padding: "8px 0" }}>
                        <Form.Item
                          label=""
                          className="description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: t("enter_your_message"),
                            },
                          ]}
                        >
                          <Input.TextArea rows={6} placeholder={t("service_description")} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                      <div className="ContactUsbtn" style={{ padding: "8px 0" }}>
                        <Form.Item>
                          <Button className="" type="primary" htmlType="submit">
                            {t("form_submit")}
                          </Button>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsSection;
