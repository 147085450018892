import {LANGUAGE_TYPES} from 'constants'
import {useSelector} from 'react-redux'

const InputLabel = ({label, className = 'form-label fs-6', ...rest}) => {
  const {language} = useSelector((state) => state.configs)
  return (
    <label {...rest} className={`${className} ${language === LANGUAGE_TYPES.ar && 'float-right'}`}>
      {label}
    </label>
  )
}

export default InputLabel
