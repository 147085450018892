import axios from 'axios'
import {
    TESTIMONIAL
} from '../endpoints'
const api = axios.create()

export const testimonial = async () => {

  return  await api.get(TESTIMONIAL, {})
}

