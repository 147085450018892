// helperFunctions.js

import { LANGUAGE_TYPES } from 'constants';

export const getHeadingStyle = (title, style, styles) => (
    title === "contact us" ? style : styles
  );
  
  export const renderHeadingForContactUs = (language, name, name_ar) => (
    <h1 className="textcolorwhite" style={{}}>
      {language === LANGUAGE_TYPES.en ? name : name_ar}
    </h1>
  );
  
  export const renderHeadingForWhoWeAre = (language, name, name_ar) => {
    const text = language === LANGUAGE_TYPES.en ? name : name_ar;
    const missionIndex = text.indexOf("Mission,");
    const firstLine = missionIndex >= 0 ? text.substring(0, missionIndex + "Mission,".length) : text;
    const secondLine = missionIndex >= 0 ? text.substring(missionIndex + "Mission,".length).trim() : "";
    
    return (
      <h1 className="textcolorwhite" style={{}}>
        <div>
          {firstLine}
          <br />
          <span className="textcolorgreen">{secondLine}</span>
        </div>
      </h1>
    );
  };
  
  export const renderDefaultHeading = (language, englishNameParts, arabicNameParts) => (
    <h1 className="textcolorwhite" style={{}}>
      {language === LANGUAGE_TYPES.en ? (
        <>
          {englishNameParts?.part1}
          <br />
          {englishNameParts?.part2?.split("Became")[0]} 
          <span style={{ color: 'green' }}>Became</span> 
          {englishNameParts?.part2?.split("Became")[1]} 
        </>
      ) : (
        <>
          {arabicNameParts?.part1}
          <br />
          {arabicNameParts?.part2?.split("صارت")[0]} 
          <span style={{ color: 'green' }}>صارت</span> 
          {arabicNameParts?.part2?.split("صارت")[1]} 
        </>
      )}
    </h1>
  );
  
  export const renderDescription = (language, description, description_ar) => (
    <p className="textcolorwhite">
      {language === LANGUAGE_TYPES.en ? (
        <>
          <span>{description?.split("complete")[0]}</span>
          <br />
          <span>{`complete ${description?.split("complete")[1]}`}</span>
        </>
      ) : (
        <>
          <span>{description_ar?.split("تجهيز")[0]}</span>
          <br />
          <span>{`تجهيز ${description_ar?.split("تجهيز")[1]}`}</span>
        </>
      )}
    </p>
  );
  