import { legacy_createStore as createStore } from 'redux';
import { persistStore } from 'redux-persist';
import { applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import AllReducers from './reducers';
// import {composeWithDevTools} from "redux-devtools-extension";


// export const store = createStore(AllReducers, composeWithDevTools(applyMiddleware(thunk)));
export const store = createStore(AllReducers, applyMiddleware(thunk));
export const persister = persistStore(store);
