import { Col, Row } from 'antd'
import React , { useEffect, useState } from 'react'
import chart1Icon from "../assets/images/Chart1.png";
import chart2Icon from "../assets/images/Chart2.png";
import AIcolimg from "../assets/images/AIcolimg.png";
import { getSections } from "../services/api/actions"
import {useSelector} from 'react-redux'
import {LANGUAGE_TYPES} from "constants";


const AiServiceSection = () => {

    const [sectionThree, setSectionThreeDetail] = useState('')
    const {language} = useSelector((state) => state.configs)


    let title = sectionThree && language === LANGUAGE_TYPES.en  ? sectionThree.title : sectionThree.title_ar
    let name = sectionThree && language === LANGUAGE_TYPES.en  ? sectionThree.name : sectionThree.name_ar
    let long_description = sectionThree && language === LANGUAGE_TYPES.en  ? sectionThree.long_description : sectionThree.long_description_ar

    // let title = sectionThree.title ?? "";

    if (title === undefined){
        title = '';
    }
    title = title.split(" ");

    useEffect(() => {

        getSectionDetails()
    }, [])


    const getSectionDetails = () => {
        getSections()
            .then((res) => {
                const {data} = res
                console.log('data', data.data)
                setSectionThreeDetail(data.data[2])
                // setSectionTwoDetail(data.data[1])
            })
            .catch((error) => {

            })
    }

  return (
    <>
    <section id="AiSection" dir={language === LANGUAGE_TYPES.en ? 'ltr' : 'rtl'}>
        <div className="AiSection container">
          <Row gutter={[16, 16]}>
            <Col className="center"  xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="leftcolcontent">
                <h5>{name} </h5>
                {/*<h1>*/}
                {/*    {sectionThree.title}*/}
                {/*<span className="textcolorgreen">Empowering</span> People By <br />*/}
                {/*Using   <span className="textcolorgreen">AI</span> Effectively*/}
    
                {/*</h1>*/}



                  <div className="AiSectionHeading">
                  {title.map((word, index) => (

                      (index === 4 ?
                              <span className={`${index === 4 ? "textcolorgreen" : ""}`}>
                          {word}&nbsp;
                                  {index === 3 ? <br /> : ""}
                            </span>
                              :  `${word}  `
                      )


                  ))}
                  </div>
                  &nbsp;


                  
                  <p >
                    {long_description}
                </p>
                <div className="AICharts">
                  <div className='chart'>
                  <img
                        src={chart1Icon}
                        alt={"logo"}
                        className="chartIcon"
                        style={{ height: "auto" }}
                      />
                       <span>
                        {/*<p>Machine Learning</p>*/}
                       <h5>AI</h5>
                      </span>
                  </div>
                  <div className='chart'>
                  <img
                        src={chart2Icon}
                        alt={"logo"}
                        className="chartIcon"
                        style={{ height: "auto" }}
                      />
                      <span>
                        {/*<p>Machine Learning</p>*/}
                       <h5>OCR</h5>
                      </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="rightcol" xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className='AiImgContainer'>

           
            <img
                        src={`${process.env.REACT_APP_API_URL}/public/uploads/${sectionThree.image}`}
                        alt={"logo"}
                        className="AIcolimg"
                        style={{ height: "auto" }}
                      />
                         </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  )
}

export default AiServiceSection