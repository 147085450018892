import {useSelector} from 'react-redux'
import Select from './form/select'
import {LANGUAGE_TYPES, LANGUAGES} from 'constants'
import {useDispatch} from 'react-redux'
import {updateLanguage} from 'store/actions/configs'

const LanguageSelect = () => {
  const dispatch = useDispatch()
  const {language} = useSelector((state) => state.configs)

  const onChange = (value) => dispatch(updateLanguage(value))

  return (
    <div style={{maxWidth: 125}}>
      <Select
        defaultOption={false}
        placeholder='Language'
        valueField='value'
        labelField='label'
        options={LANGUAGES}
        className={`wrapper ${language === LANGUAGE_TYPES.en ? 'wrapper-left' : 'wrapper-right'} mb-3 mb-lg-0`}
        value={language}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
    </div>
  )
}

export default LanguageSelect
