import React from 'react'
import { useTranslation } from 'react-i18next';


const AccountSection = ({ backgroundImage }) =>  {
  const { t } = useTranslation();
    const sectionStyle = {
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'inherit',
      backgroundSize: 'cover',
      
    };
  return (
    <section id="AccountService" style={sectionStyle}>
    {/*<div className="AccountServiceSection container">
      <div className="AccountServiceSection-content">
        <h2>
         {t('need_an_experienced')} <br />
          <span className="textcolorgreen">{t('Twallam')}</span> {t('banner_title')}
        </h2>
        <p>
          {t('banner_description')}
        </p>
        <button className="GetStarted" type="button">
         {t('get_started')}
        </button>
      </div>
    </div>*/}
  </section>
  )
}

export default AccountSection

