import React, { useEffect, useState } from "react";

import { Header } from "../../components/Header/Header";
import Footer from "../../components/Footer";
import configs from "../../configs/index";
import { getAdminUrl } from "helpers";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { LANGUAGE_TYPES } from "constants";

const Thankyou = () => {
  //Get query param
  let location = useLocation();
  const params = new URLSearchParams(location.search);

  const redirectParam = params.get("redirect");
  const { language } = useSelector((state) => state.configs);
  const langParam = language === LANGUAGE_TYPES.en ? "en" : "ar";

  useEffect(() => {
    localStorage.removeItem("packages");
  });
  // const adminApiUrl=configs.adminApiUrl;
  const adminApiUrl = `${getAdminUrl()}?lang=${langParam}`;

  return (
    <div id="thankyouform">
      <Header />
      <div className="container form">
        <h1 className="FormTitle text-center">Thank You</h1>
        {redirectParam === "subscribe" ? (
          <p className="FormTitle text-center">Thank you for subscribing to our platform! We're excited to have you on board.</p>
        ) : redirectParam === "contact" ? (
          <p className="FormTitle text-center">Thank you for reaching out to us! We have received your message and will get back to you as soon as possible. If your inquiry is urgent, please use the phone number below to talk to one of our team members. Thank you for your patience.</p>
        ) : (
          <p className="FormTitle text-center">
            Thank you for the subscription, please{" "}
            <a href={adminApiUrl} target="_blank" rel="noopener noreferrer">
              Click here
            </a>{" "}
            to login your account.
          </p>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Thankyou;
