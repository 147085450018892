import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import { Header } from "../../components/Header/Header";
import homeBackground from "../../assets/images/AccountServiceBG.png";


import BusinessSection from "../../components/BusinessSection";
import AccountSection from "../../components/AccountSection";
import OcrSection from "../../components/OcrSection";
import PricingSection from "../../components/PricingSection";
import DownloadNowSection from "../../components/DownloadNowSection";
import ContactUsSection from "../../components/ContactUsSection";
import TestimonialsSection from "../../components/TestimonialsSection";
import AiServiceSection from "../../components/AiServiceSection";

import { slider } from "services/api/actions/slider";
import WelcomeSection from "components/WelcomeSection";

const Home = () => {
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    slider({})
      .then((response) => {
        // Log the entire response to the console
        console.log("API Response:", response);
        
        const lastIndex = response.data.data.length - 1;
        setSliders(response.data.data[lastIndex]);
      })
      .catch((error) => {
        console.error("Error fetching sliders:", error);
      });
  }, []);

  return (
    <>
      <Header />
      <main>
        <WelcomeSection imageUrl={sliders.image} title={sliders.title} title_ar={sliders.title_ar} name={sliders.name} name_ar={sliders.name_ar} description={sliders.description} description_ar={sliders.description_ar} />
        <OcrSection />
        <BusinessSection />
        {/*<PricingSection/>*/}
        <AiServiceSection />
        {/*<TestimonialsSection/>*/}
        <ContactUsSection />
        {/*<DownloadNowSection/>*/}
        {/*<AccountSection backgroundImage={homeBackground}/>*/}
      </main>
      <Footer />
    </>
  );
};

export default Home;
