import {DEFAULT_MODULE_PERMISSION, LANGUAGE_TYPES} from 'constants'
import { UPDATE_LANGUAGE } from '../types'

const iState = {
  isOnline: true,
  language: LANGUAGE_TYPES.en,
  modules: [],
  default_permissions: {},
}

const Configs = (state = iState, action) => {
  console.log('action.type',action.type)
  console.log('action.payload', action.payload)

  switch (action.type) {


    case UPDATE_LANGUAGE: {
      return {...state, language: action.payload}
    }

    default: {
      return state
    }
  }
}

export default Configs
