import React, { useState } from 'react'

import { Header } from '../../components/Header/Header'
import Footer from '../../components/Footer'
// import {signup} from 'services/api/actions'
import {checkOTP, resendOTP} from '../../services/api/actions'
import { useNavigate, useParams } from "react-router-dom"
import {toast} from 'react-toastify'



import { Form, Input, Row, Col, Button, Checkbox, Radio } from "antd";

// import { post } from "../api/index";


const Otp = () => {

    const [form] = Form.useForm();
    const navigate = useNavigate();
    let { type } = useParams();
    const [value, setValue] = useState(type === 'sme' ? 1 : 2);
    let user = JSON.parse(localStorage.getItem('user'));

    console.log('type',type)



    const  initialValues={
        remember: true,
        type: type === 'sme' ? 1 : 2
    }
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    const onFinish = async (values) => {
        console.log("Received values of form: ", values);
        console.log("in handleSubmit");
        try {
            // await form.validateFields();
            let formData = await form.validateFields();
            console.log("Received values of form: ", formData);

            let user = JSON.parse(localStorage.getItem('user'));
            formData.id = user.id;

            console.log("Received values of form after:  ", formData);
            console.log("user:  ", user);

            // signup(formData)
            //     .then((res) => {
            //         console.log('res.data',res)
            //         // localStorage.setItem('user',JSON.stringify(res.data))
            //         // navigate(`/pricing`);
            //     })
            //     .catch((error) => {
            //
            //         console.log('error',error)
            //     })

            //
            try {
                checkOTP(formData)
                    .then(response => {
                        console.log('res.data',response)
                        if (response.data.meta.code === 404){

                            toast.error(response.data.meta.message)
                        }
                        else {
                            navigate(`/pricing?sn=yes`);
                        }
                    }).catch((error) => {
                    console.log('errors here',error);
                })

            } catch (error) {
                console.error("Error package list issue:", error);

            }
            // const newData = {

            //   cnicNumber: values.cnicNumber,
            //   nomineetitle: values.nomineetitle,
            //   applicantName: values.applicantName,
            //   nomineeRelationName: values.nomineeRelationName,
            //   nomineeMaritalStatus: values.nomineeMaritalStatus,
            //   nomineeDob: values.nomineeDob,

            // };

            // await post("signUp", newData);
            // form.resetFields();
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    const OtpResend = async () => {

        // var formData = new FormData();
        var formData = {
            id: user.id,
            name: user.name,
            email: user.email,
        };
        // formData.id = user.id;
        // formData.name = user.name;
        // formData.email = user.email;

        console.log('formData',formData)
        resendOTP(formData)
            .then(response => {
                    navigate(`/pricing?sn=yes`);
            }).catch((error) => {
            console.log('errors here',error);
        })
    }




    return (
        <div>
            <Header />
            <div className="container form">
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    name="otpForm"
                    className="OtpForm"
                    initialValues={initialValues}
                    onFinish={onFinish}
                >

       <div className="otpsectionheading">
        <div className="SectionHeadingcontent">
          <p >Each time you sign in, you will need your password and a verification code</p>
          <h1 >
          2 Step <span className="textcolorgreen">Authentication</span> 
          </h1>
       
        </div>
      </div>
                  
      <div className="otpheading">
        <div className="SectionHeadingcontent">
        
          <h1 >
          Confirm that it works!
          </h1>
          <p >Twallam just sent you an OTP code via email.</p>
        </div>
      </div>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={10} lg={11} xl={8}>
                            <div style={{ padding: "8px 0" }}>
                                <Form.Item

                                    name="otp"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter the code",
                                        },
                                        {
                                            pattern: /^[0-9]*$/,
                                            message: "Otp code is number.",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter the code" />
                                </Form.Item>

                            </div>
                        </Col>

                    </Row>

                    <Row gutter={[16, 16]}>
                     
                        <Col xs={24} sm={24} md={10} lg={11} xl={4}>
                            <Form.Item className='resendBtnInput'>
                            <p className='btnStartingText'>Didn't get?</p>
                                <Button className='resendbtn' onClick={OtpResend} type="primary" htmlType="button" >
                                    Resend.
                                </Button>

                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={10} lg={11} xl={3}>
                            <div className='Signupbtn' style={{ padding: "8px 0" }}>
                                <Form.Item>
                                    <Button className='backbtn' type="primary" htmlType="submit" >
                                        Back
                                    </Button>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={10} lg={11} xl={4}>
                            <div className='Signupbtn' style={{ padding: "8px 0" }}>
                                <Form.Item>
                                    <Button className='continuebtn' type="primary" htmlType="submit" >
                                        Continue
                                    </Button>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}></Row>

            <Footer />
        </div>
    )
}

export default Otp