import axios from "axios";
import { HOMEOCRSTEPSLIST, GETSECTIONDETAIL, GETSECTIONS } from "../endpoints";
const api = axios.create();

export const ocrSteps = async () => {
  return await api.get(HOMEOCRSTEPSLIST, {});
};

export const getSectionDetail = async (id) => await api.get(`${GETSECTIONDETAIL}?section_id=${id}`)
export const getSections = async () => await api.get(`${GETSECTIONS}`)

