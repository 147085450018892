import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import CardSection from "./CardSection";
import { Col, Form, Input, Row } from "antd";
import { attachPaymentMethod, signup } from "../../services/api/actions";
import stripelogo from "../../assets/logos/stripe-logo.png";
// import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom";
import TwallamLogo from "../../assets/logos/headerLogo.png";

class CheckoutForm extends React.Component {
  thankYou = async (event) => {};

  handleSubmit = async (event) => {
    console.log("handle");
    event.preventDefault();

    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }

    let product_id = localStorage.getItem("product_id");
    let packages = localStorage.getItem("packages");
    const card = elements.getElement(CardElement);
    // const navigate = useNavigate();

    console.log("card", card);
    console.log("product_id", product_id);

    const result = await stripe.createToken(card);
    if (result.error) {
      console.log(result.error.message);
    } else {
      console.log(result);
      let user = JSON.parse(localStorage.getItem("user"));

      console.log("user", user);
      result.product_id = product_id;
      result.jwt_token = user.ACCESS_TOKEN;
      attachPaymentMethod(result)
        .then(() => {
          // localStorage.removeItem("packages");
          // if (packages == "free") {
          //   localStorage.setItem("packages", "free");
          // } else {
          localStorage.setItem("packages", product_id);
          //  }

          document.getElementById("thankyou").click();
          // history.push('/')
          // this.props.history.push('/thankyou')
          //     <Navigate to='/thankyou' />
          //    <Navigate replace to="/thankyou" />
          // navigate(`/thankyou`);
        })
        .catch((error) => {});
    }
  };

  render() {
    return (
      <>
        <h5 className="stripeheadingtext">All Transactions are Secure and Encrypted</h5>
        <h1 className="stripeheading text-center">
          {" "}
          Strip <span className="textcolorgreen">Payment</span>
        </h1>

        <div className="paymentcontainer">
          <Link id="thankyou" to="/thankyou"></Link>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={10} lg={12} xl={12}>
              <form onSubmit={this.handleSubmit}>
                <h3 className="paymentHeading">Add your Payment Details</h3>
                <CardSection />
                <button disabled={!this.props.stripe} className="btn-pay">
                  Buy Now
                </button>
              </form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default CheckoutForm;
