export const LANGUAGE_TYPES = {
    en: 'en',
    ar: 'ar',
}

export const DEFAULT_LANGUAGE = LANGUAGE_TYPES.en

export const LANGUAGES = [
    {
        label: 'English', 
        value: LANGUAGE_TYPES.en,

    },
    {
        label: 'عربی', 
        value: LANGUAGE_TYPES.ar,
      
    },
]
