import {LANGUAGE_TYPES} from 'constants'
import {useSelector} from 'react-redux'

const InputError = ({error}) => {
  const {language} = useSelector((state) => state.configs)
  return (
    <div className='fv-plugins-message-container'>
      <div className={`fv-help-block ${language === LANGUAGE_TYPES.ar && 'text-align-end'}`}>
        {error}
      </div>
    </div>
  )
}

export default InputError
