import {useEffect} from 'react'
import './assets/style/index.css'
import { PublicRoutes } from './routing/PublicRoutes';
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'


function App() {

    // const language = 'en';
    const {language} = useSelector((state) => state.configs)

    const {
        i18n: {changeLanguage},
    } = useTranslation()

    useEffect(() => {
        changeLanguage(language)
    }, [language])

  return (
      <>
      <ToastContainer
          autoClose={4000}
          draggable={false}
          position='top-right'
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnHover
      />
    <PublicRoutes/>
      </>
  );
}

export default App;
