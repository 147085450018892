import React, { useEffect, useState } from "react";
import { List } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useSelector } from 'react-redux';
import { LANGUAGE_TYPES } from "constants";
import { useTranslation } from 'react-i18next';
import { getSections } from "../services/api/actions";
import { useNavigate } from 'react-router';

const BusinessSection = () => {
    const [section, setSectionDetail] = useState({});
    const [sectionTwo, setSectionTwoDetail] = useState({});
    const { t } = useTranslation();
    const { language } = useSelector((state) => state.configs);
    const navigate = useNavigate();

    const data = [
        { title: t('Speed and accuracy in data entry') },
        { title: t('Faster decision-making') },
        { title: t('Submitting tax and zakat returns.') },
    ];

    // Function to split English text at the word "your"
    const splitText = (fullText) => {
        if (!fullText) return { part1: "", part2: "" };
        const splitIndex = fullText.indexOf("your");
        if (splitIndex !== -1) {
            return {
                part1: fullText.slice(0, splitIndex).trim(),
                part2: fullText.slice(splitIndex).trim(),
            };
        }
        return { part1: fullText, part2: "" };
    };

    // Function to split Arabic text at the word "حساباتك"
    const splitTextArabic = (fullText) => {
        if (!fullText) return { part1: "", part2: "" };
        const splitIndex = fullText.indexOf("حساباتك");
        if (splitIndex !== -1) {
            return {
                part1: fullText.slice(0, splitIndex).trim(),
                part2: fullText.slice(splitIndex).trim(),
            };
        }
        return { part1: fullText, part2: "" };
    };

    const getSectionDetails = async () => {
        try {
            const res = await getSections();
            const { data } = res;
            console.log("data", data);
            
            setSectionDetail(data.data[0] || {});
            setSectionTwoDetail(data.data[1] || {});
        } catch (error) {
            console.error("Error fetching section details:", error);
        }
    };

    useEffect(() => {
        getSectionDetails();
    }, []);

    const titleText = language === LANGUAGE_TYPES.en ? section.title : section.title_ar;
    const { part1, part2 } = language === LANGUAGE_TYPES.en 
        ? splitText(titleText || '') 
        : splitTextArabic(titleText || '');

    return (
        <section id="BusinessSection" className={`wrapper ${language === LANGUAGE_TYPES.en ? 'wrapper-left' : 'rtl'}`}>
            <div className="BusinessSection container" style={{ height: "100%", gap: "5rem" }}>


                
                <div className="BSleft">
                    <div className="BSleft-content">
                        <div className="ImgContainer">
                            <img
                                src={`${process.env.REACT_APP_API_URL}/public/uploads/${sectionTwo.image || ''}`}
                                alt={"logo"}
                                className="BSleftimg"
                                style={{ height: "auto" }}
                            />
                        </div>

                        {/* <h4 className={`poppins-semibold ${language !== LANGUAGE_TYPES.en ? 'arabic-text' : ''}`}>
                            {language === LANGUAGE_TYPES.en
                                ? sectionTwo.title?.split(" ").map((word, index) => (
                                    <span key={index} className={`${index === 4 ? "textcolorgreen" : ""}`}>
                                        {word}&nbsp;
                                        {index === 3 ? <br /> : ""}
                                    </span>
                                ))
                                : sectionTwo.title_ar
                            }
                        </h4> */}

                        <h4 className="poppins-semibold">
                            {language === LANGUAGE_TYPES.en ? section.title : section.title_ar}
                        </h4>

                        <p className={`textcolorgray ${language !== LANGUAGE_TYPES.en ? 'arabic-text' : ''}`}>
                            {sectionTwo && language === LANGUAGE_TYPES.en ? sectionTwo.long_description : sectionTwo.long_description_ar}
                        </p>

                        <button className="InTouchbtn textcolorgreen" onClick={() => navigate('/about')} type="button">
                            {t('Get in Touch')}
                        </button>
                    </div>
                </div>

                <div className="BSRight" style={{ height: "100%" }}>
                    <div className="BSRight-content">
                        <h5 className={language !== LANGUAGE_TYPES.EN ? 'arabic-texts' : ''}>
                            {language === LANGUAGE_TYPES.en ? section.name : section.name_ar && 'مرحبا بكم في تولام' }
                        </h5>

                        <h3 className={`poppins-bold ${language !== LANGUAGE_TYPES.en ? 'arabic-text' : ''}`}>
                            {language === LANGUAGE_TYPES.en
                                ? <>
                                  
                                  {part1}
              
              
        
              {part2?.split("your")[0]} {/* Text before 'Became' */}
              <span className="textcolorgreen" >your</span> 
              {part2?.split("your")[1]} {/* Text after 'Became' */}
                                  </>
                                : <>
                                    <span>{part1}</span>
                                    <span className="textcolorgreen">{part2}</span>
                                  </>
                            }
                        </h3>

                        <p className={`textcolorgray ${language !== LANGUAGE_TYPES.en ? 'arabic-text' : ''}`}>
                            {language === LANGUAGE_TYPES.en ? section.long_description : section.long_description_ar}
                        </p>

                        <List className="CustomList"
                            dataSource={data}
                            renderItem={(item) => (
                                <List.Item>
                                    <span className={`ListItemSpan ${language === LANGUAGE_TYPES.ar ? 'List-margin-left' : ''}`}>
                                        <CheckOutlined className="CheckMark" />
                                    </span>
                                    <span className="ListItemtext">{item.title}</span>
                                </List.Item>
                            )}
                        />
                    </div>
                </div>



            </div>
        </section>
    );
};

export default BusinessSection;
