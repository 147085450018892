import React from "react";
import ReactDOM from "react-dom/client";
// import './index.css';
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import reportWebVitals from "./reportWebVitals";
import './i18n';
import {store, persister} from './store'
import {PersistGate} from 'redux-persist/es/integration/react'
import {Provider} from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById("root"));
const {PUBLIC_URL} = process.env
root.render(
  // <React.StrictMode>
  // <App />
  // </React.StrictMode>
    <GoogleOAuthProvider clientId="138446635165-ei6spj219mibga7id2phj3agpvsj2u3d.apps.googleusercontent.com">
    <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
            <App basename={PUBLIC_URL} />
        </PersistGate>
    </Provider>
    </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
