import React from "react";
import clsx from "clsx";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TopNavbar from "./TopNavbar.jsx";
import TwallamLogo from "../../assets/logos/headerLogo.png";
import TwallamLogoEng from "../../assets/logos/TwallamHeaderEng.png";
import { Link } from "react-router-dom";
import { LANGUAGE_TYPES } from "constants";
import { getAdminUrl } from "helpers";
const toolbarButtonMarginClass = ""; // 'ms-1 ms-lg-3'

export const Header = () => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.configs);
  const langParam = language === LANGUAGE_TYPES.en ? "en" : "ar";
  const loginUrl = `${getAdminUrl()}?lang=${langParam}`;

  return (
    <>
      <div className="header">
        <TopNavbar />
        <Navbar expand="lg" className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left MiddleNavBar" : "wrapper-right MiddleNavBar"} d-flex flex-column flex-row-fluid`}>
          <Container>
            <Navbar.Brand as={Link} to="/">
              {" "}
              <img src={`${language === LANGUAGE_TYPES.en ? TwallamLogoEng : TwallamLogo}`} alt={"logo"} className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "wrapper-right"} TwallamLogo`} style={{ height: "auto" }} />
              {/*<img src={TwallamLogo} alt={"logo"} className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "wrapper-right"} TwallamLogo`} style={{ height: "auto" }} />*/}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "wrapper-right"} ms-auto`}>
                <Nav.Link as={Link} to="/">
                  {t("Home")}
                </Nav.Link>

                <Nav.Link as={Link} to="/about">
                  {t("About")}
                </Nav.Link>
                <Nav.Link as={Link} to="/pricing">
                  {t("Packages")}
                </Nav.Link>
                <Nav.Link as={Link} to="/contact">
                  {t("Contact")}
                </Nav.Link>
                {/*<Nav.Link href="/signup/sme" className="NavSignUpBtn SMEbtn">Sign up as SME</Nav.Link>*/}
                {/*<Nav.Link href="/signup/in" className="Individualbtn NavSignUpBtn">Sign up as Individual</Nav.Link>*/}
                <Nav.Link href="/signup" className="NavSignUpBtn SMEbtn">
                  {t("Signup")}
                </Nav.Link>
                <Nav.Link href={loginUrl} target="_blank" className="Individualbtn NavSignUpBtn">
                  {t("sign_in")}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};
