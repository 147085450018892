import React, { useState } from "react";

import { Header } from "../../components/Header/Header";
import Footer from "../../components/Footer";
// import {signup} from 'services/api/actions'
import { signup } from "../../services/api/actions";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, ElementsConsumer, Elements } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";

import CheckoutForm from "./CheckoutForm";

import { Form, Input, Row, Col, Button, Checkbox, Radio } from "antd";

// import { post } from "../api/index";

const Stripe = () => {
  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  // const STRIPE_PUBLISHABLE_KEY  = 'pk_test_51OZ6Q3JAx96otuOjA3mNwLuvdX5wlCMDqWY8dxB35fED0Gw9JuYxBjg5R9e0aZdiRcPHJ2fBdwKGdBA6XOIJ3Gyw00DjfbcYUy';
  const STRIPE_PUBLISHABLE_KEY = "pk_test_51MPNmZAmjpmKiIpxOPgBvB8pRZR0KoE6KFdEyOCGY6F4R6KwHPiWG4z6B0vZdkFn6DN1Z1x9UsXtDX2njOTaNGkI00fmuLeg5J";
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  const location = useLocation();
  const CARD_ELEMENT_OPTIONS = {
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
      base: {
        iconColor: "rgb(240, 57, 122)",
        color: "rgb(240, 57, 122)",
        fontSize: "16px",
        fontFamily: '"Open Sans", sans-serif',
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#CFD7DF",
        },
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "#303238",
        },
      },
    },
  };

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const onFinish = async (values) => {
    console.log("Received values of form: ", values);
    console.log("in handleSubmit");
    try {
      // await form.validateFields();
      const formData = await form.validateFields();
      console.log("Received values of form: ", formData);

      signup(formData)
        .then(() => {})
        .catch((error) => {});
      // const newData = {

      //   cnicNumber: values.cnicNumber,
      //   nomineetitle: values.nomineetitle,
      //   applicantName: values.applicantName,
      //   nomineeRelationName: values.nomineeRelationName,
      //   nomineeMaritalStatus: values.nomineeMaritalStatus,
      //   nomineeDob: values.nomineeDob,

      // };

      // await post("signUp", newData);
      // form.resetFields();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    // <Elements stripe={stripePromise}>
    //     {/*<CheckoutForm />*/}
    //     <ElementsConsumer>
    //         {({ stripe, elements }) => (
    //             <CheckoutForm stripe={stripe} elements={elements} />
    //         )}
    //     </ElementsConsumer>
    // </Elements>
    <Elements stripe={stripePromise}>
      <div>
        <Header />
        <div className="container form">
          <ElementsConsumer>{({ stripe, elements }) => <CheckoutForm stripe={stripe} elements={elements} />}</ElementsConsumer>
        </div>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}></Row>

        <Footer />
      </div>
    </Elements>
  );
};

export default Stripe;
